import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound404 from '../views/404.vue'
import Header from '../views/partial/Header.vue'
import Footer from '../views/partial/Footer.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Forside',
    components: {
      default: Home,
      header: Header,
      footer: Footer
    },
    meta: {
      title: 'Bed and Breakfast ved Gendarmstien | Iller Slot',
      metaTags: [
        {
          name: 'description',
          content:
            'Bed and Breakfast med kort afstand til Gendarmstien! Vi tilbyder værelser i forskellige størrelser samt morgenmad og aftensmad.',
        },
        {
          property: 'og:title',
          content: 'Bed and Breakfast ved Gendarmstien | Iller Slot',
        },
        {
          property: 'og:site_name',
          content: 'illerslot.dk',
        },
        {
          property: 'og:url',
          content: 'https://illerslot.dk',
        },
        {
          property: 'og:description',
          content:
            'Bed and Breakfast med kort afstand til Gendarmstien! Vi tilbyder værelser i forskellige størrelser samt morgenmad og aftensmad.',
        },
        {
          property: 'og:image',
          content:
            'https://illerslot.dk/img/pv/pv92.jpeg',
        }
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://illerslot.dk',
        },
      ],
    },
  },
  {
    path: '/info',
    name: 'Information',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: function() {
        return import(/* webpackChunkName: "about" */ '../views/Info.vue')
      },
      header: Header,
      footer: Footer
    },
    meta: {
      title: 'Information & Historien | Iller Slot',
      metaTags: [
        {
          name: 'description',
          content:
            'Læs om muligheder og andet om Iller Slot. Læs historien, læs om muligheder for arrangementer og andet!',
        },
        {
          property: 'og:title',
          content: 'Information & Historien | Iller Slot',
        },
        {
          property: 'og:site_name',
          content: 'illerslot.dk',
        },
        {
          property: 'og:url',
          content: 'https://illerslot.dk/info',
        },
        {
          property: 'og:description',
          content:
            'Læs om muligheder og andet om Iller Slot. Læs historien, læs om muligheder for arrangementer og andet!',
        }
      ],
    },
  },
  {
    path: '/priser',
    name: 'Priser',
    components: {
      default: function() {
        return import('../views/Priser.vue')
      },
      header: Header,
      footer: Footer
    },
    meta: {
      title: 'Priser og Ydelser | Iller Slot',
      metaTags: [
        {
          name: 'description',
          content:
            'Få et overblik over de mange priser og diverse ydelser vi tilbyder.',
        },
        {
          property: 'og:title',
          content: 'Priser og Ydelser | Iller Slot',
        },
        {
          property: 'og:site_name',
          content: 'illerslot.dk',
        },
        {
          property: 'og:url',
          content: 'https://illerslot.dk/priser',
        },
        {
          property: 'og:description',
          content:
            'Få et overblik over de mange priser og diverse ydelser vi tilbyder.',
        }
      ],
    },
  }
]

const eventRoutes = [
  {
    path: '/events/brunch',
    name: 'Brunch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: function() {
        return import(/* webpackChunkName: "about" */ '../views/events/Brunch.vue')
      },
      header: Header,
      footer: Footer
    },
    meta: {
      title: 'Brunch | Iller Slot',
      metaTags: [
        {
          name: 'description',
          content:
            'Kom til brunch hos Iller Slot.'
        },
        {
          property: 'og:title',
          content: 'Brunch | Iller Slot',
        },
        {
          property: 'og:site_name',
          content: 'illerslot.dk',
        },
        {
          property: 'og:url',
          content: 'https://illerslot.dk/events/brunch',
        },
        {
          property: 'og:description',
          content:
            'Kom til brunch hos Iller Slot.',
        }
      ],
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes.concat(eventRoutes),
})

//router.addRoute(eventRoutes)

// Not needed as we have htaccess to take care of this now.
// Just kept here for redundancy
router.addRoute({ path: '/:catchAll(.*)', component: NotFound404
})

// Makes meta tags work
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]')
  ).map((el) => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))

  next()
})

export default router
