import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import Header from './views/partial/Header.vue'
import Footer from './views/partial/Footer.vue'
// GOOGLE ANALYTICS
//import VueGtag from "vue-gtag"

Vue.config.productionTip = false
// GOOGLE ANALYTICS
//Vue.use(VueGtag, {
//  config: {id: 'UA-96318176-1'},
//  enabled: false
//}, router)

new Vue({
  router,
  i18n,
  render: function (h) {
    return h(App, [
      h(Header, { slot: 'header' }),
      h(Footer, { slot: 'footer' })
    ])
  }
}).$mount('#app')
