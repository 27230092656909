<template>
  <header>
    <div class="c">
      <p id="name">{{ $t('header.title') }}</p>
      <nav>
        <ul>
          <li>
            <router-link to="/">{{ $t('header.nav.home') }}</router-link>
          </li>
          <li>
            <router-link to="/priser">{{ $t('header.nav.prices') }}</router-link>
          </li>
          <li>
            <router-link to="/info">{{ $t('header.nav.info') }}</router-link>
          </li>
          <li>
            <a href="https://www.booksonderjylland.dk/da/iller-slot">{{ $t('header.nav.bookNow') }}</a>
          </li>
        </ul>
        <div>
          <a href="#" @click="setLocale('da')">
            <img src="../../assets/img/dk.svg" alt="Danmarksflag ikon som skifter sproget til dansk." />
          </a>
          <!--<a href="#" @click="setLocale('de')">
              <img src="./assets/img/de.svg" alt="Tysklands flag" />
            </a>-->
          <a href="#" @click="setLocale('en')">
            <img src="../../assets/img/gb.svg" alt="UK Flag icon changing the language to English." />
          </a>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    setLocale(lang) {
      this.$emit('setLocale', lang); // Emit an event to change the locale
    },
  },
};
</script>