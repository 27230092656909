<template>
  <div id="app">
    <router-view name="header" @setLocale="setLocale" />
    <router-view />
    <router-view name="footer" />
  </div>
</template>

<script>
export default {
  methods: {
    setLocale(lang) {
      this.$i18n.locale = lang; // da, en, de
    },
  },
};
</script>

<style>
/* #region main */
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "open sans";
  src: url("./assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "open sans";
  src: url("./assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "open sans";
  src: url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: "open sans";
  color: #111;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.6rem;
}

.c {
  width: 1250px;
  max-width: 100%;
  padding: 0 30px;
  margin: 0 auto;
}

body {
  background-color: #f9f9f9;
}

body > section {
  width: 100%;
}

#name {
  font-weight: 700;
  font-size: 1.6rem;
}

header {
  width: 100%;
  min-height: 70px;
  border-bottom: 1px solid #ddd;
  background-color: rgb(255, 255, 255);
}

header .c {
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header > div > h2 {
  font-weight: 700;
}

header > div > nav {
  display: flex;
  align-items: center;
}

header > div > nav > ul {
  display: flex;
  align-items: flex-start;
  list-style-type: none;
}

header > div > nav > ul > li:not(:first-of-type) {
  margin-left: 15px;
}

header > div > nav > ul > li > a {
  text-decoration: none;
  color: #777;
}

header > div > nav > ul > li > a.router-link-exact-active {
  color: #111;
  border-bottom: 2px solid #111;
}

header > div > nav > div {
  display: flex;
  align-items: flex-start;
  margin-left: 16px;
}

/* Flags */
header > div > nav > div > a > img {
  width: 26px;
}

header > div > nav > div > a > img:hover {
  cursor: pointer;
}

header > div > nav > div > a:not(:first-of-type) {
  margin-left: 5px;
}

/* Footer */
footer {
  width: 100%;
  background-color: #303033;
}

footer > article {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0;
}

footer p,
footer a,
footer h2 {
  color: #fff;
}

footer > article > div {
  width: calc(100% / 3);
  height: 300px;
  padding: 40px;
}

#kontrolrapport {
  align-self: center;
}

#some {
  display: flex;
  align-items: center;
  align-self: center;
  height: 57px;
}

#some img {
  width: 35px;
}

#some > a:nth-child(2) > img {
  width: 30px;
}

#some a:not(:first-of-type) {
  margin-left: 12px;
}

footer > article > div:not(:last-of-type) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

footer > article > div:not(:first-of-type) {
  border-left: 1px solid #444;
}

footer > article > div h2 {
  margin-bottom: 8px;
}

footer > article > div p {
  line-height: 1.35;
}

footer > article > div p:not(:first-of-type) {
  margin-top: 8px;
}

@media (max-width: 1000px) {
  footer > article {
    flex-wrap: wrap;
    justify-content: center;
  }

  footer > article > div {
    text-align: center;
  }

  footer > article > div:not(:nth-child(3)) {
    width: calc(50%);
  }

  footer > article > div:nth-child(3) {
    height: unset;
    width: 100%;
    order: -2;
    border-left: none;
    border-bottom: 1px solid #444;
  }
}

#cBanner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 750px;
  width: 60%;
  max-width: 850px;
  position: fixed;
  z-index: 9;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 12px 30px;
  border-radius: 4px 4px 0 0;
  border: 1px solid #777;
}

#cOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 25px;
}

#cOptions > *:not(:first-child) {
  margin-top: 8px;
}

.btn {
  padding: 8px 18px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  color: white;
}

.btn:hover {
  cursor: pointer;
}

.disallow {
  background-color: rgb(194, 41, 41);
}

.allow {
  background-color: rgb(35, 173, 7);
}

#cText > h2 {
  font-size: 2em;
}

@media (max-width: 600px) {
  header {
    padding: 15px 0;
  }

  header > div {
    flex-direction: column;
  }

  header > div > h2 {
    font-size: 2rem;
  }

  footer > article > div {
    width: 100% !important;
    height: unset;
    padding: 40px 25px;
  }

  footer > article > div:nth-child(2) {
    order: -1;
    border-left: none;
    border-bottom: 1px solid #444;
  }

  #some {
    height: unset;
    margin-top: 25px;
  }

  #kontrolrapport {
    margin-top: 25px;
  }
}

@media (max-width: 450px) {
  header > .c > nav {
    flex-direction: column;
  }

  header > .c > nav > div {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  header > div > nav > div > a > img {
    width: 30px;
  }

  header > div > nav > div > a:not(:first-of-type) {
    margin-left: 8px;
  }
}
</style>